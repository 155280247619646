* {
    margin: 0;
    padding: 0;
}

body {
    position: relative;
    min-width: 1200px;
    min-height: 600px;
}

#canvas-bg {
    display: block;
}